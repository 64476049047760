.contact-btn {
    display: block;
    padding: 10px 13px;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
    width: 100%;
    font-weight: 600;
    letter-spacing: .5px;
  }
  
  .contact-form label {
    line-height: 1.38;
    color: #2c3241 !important;
    font-size: 17px;
  }
  
  .contact-form input {
    font-size: 12px;
    color: #2c3241;
    /* opacity: 0.6; */
    border-radius: 2px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
  }
  .contact-form .font-size-12-input{
    font-size: 12px;
  }
  .contact-form select {
    border-radius: 3px;
    margin-bottom: 40px;
    padding: 8px 15px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    background-color: #ffffff;
    font-size: 12px;
    height: inherit !important;
    margin-bottom: 0;
  }
  
  .contact-img {
    /* width: 65%;
    height: auto;
    margin-left: 60px; */
    width: 90%;
    height: 390px;
    margin-left: 60px;
    text-align: center;
    margin-top: 15px;
  }
  
  .subscribe-img {
    width: 65%;
    height: auto;
    margin-left: 50px;
    margin-top: -10px;
  }
  .green-bg{
    background-color: #70C217 !important;
    border-color: #70C217 !important
  }