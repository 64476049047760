
      .disclaimer
      {
        .purple-background{
          background-color: #7854F7!important;
      }
      .font-fff{
          color: #fff!important;
      }
        font-size: 12px !important;
        font-family: "Roboto",sans-serif;
        h1{
            color: var(--color-primary-blue);
            font-size: 18px;
            font-weight: bolder;
            letter-spacing: -0.13px;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          p {
            font-size: 12px !important;
            margin-bottom: 18px;
        }
        .subheading{
            font-size: 18px  !important;
            font-weight: 550 !important;
            margin-bottom:5px !important;
          }
          
.subheading {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
    background: #dfe3ee;
  }
.sidenav {
  height: 100vh;
  width:5.5rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #3b5998;
  overflow-x: hidden;
  padding-top: 20px;
}
.main {
  margin-left: 5.5rem; /* Same as the width of the sidenav */
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
}
      }
      

      
      .disclaimer-ps
      {
        .purple-background{
          background-color: #E2ECF1 !important;
      }
      .font-fff{
          color: #fff!important;
      }
      .px-130{
        padding: 130px 0px!important;
      }
      .section-head-ps{
        padding-top: 8.125rem!important;
        padding-bottom: 2rem!important;
      }
      .px-45{
        padding: 45px 0px!important;
      }
      .x_content{
        h4{
          font-size: 22px;
          font-weight: 600;
          color:#111827;
          margin: 17px 0px;
        }
      }
      h6{
        font-size: 17px;
        font-weight: 600;
        color: #111827;
        margin: 17px 0px;
    }
      
    }