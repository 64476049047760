
      .privacy_policy
      {
        font-size: 12px !important;
        font-family: "Roboto",sans-serif;
        h1{
            color: var(--color-primary-blue);
            font-size: 18px;
            font-weight: bolder;
            letter-spacing: -0.13px;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          p {
            margin-bottom: 18px;
        }
        .subheading{
            font-size: 18px  !important;
            font-weight: 550;
            margin-bottom:5px;
          }
          .sidenav {
            height: 100vh;
            width:5.5rem;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: #3b5998;
            overflow-x: hidden;
            padding-top: 20px;
          }
          .main {
            margin-left: 5.5rem; /* Same as the width of the sidenav */
            padding: 0px 10px;
          }
          
          @media screen and (max-height: 450px) {
            .sidenav {padding-top: 15px;}
          }
      }
      